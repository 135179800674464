<template>
  <app-modal
    :show="showConfirmation"
    :textButtonCancel="$t('invoice.create.continue_editing')"
    @close="closeConfirmation"
  >
    <template #title>
      <span class="capitalize">{{ $t("invoice.create.send_invoice") }}</span>
    </template>
    <template #body>
      <div class="flex flex-col space-y-3 text-sm text-gray-600">
        <i18n-t keypath="invoice.create.send_invoice_hint" tag="p">
          <template v-slot:amount>
            <span class="font-bold">
              {{ $formats.currency(invoice.currency, invoice.total_amount) }}
            </span>
          </template>
          <template v-slot:customer>
            <span class="font-bold">{{ invoice.customer?.name }}</span>
          </template>
        </i18n-t>
        <p>{{ $t("invoice.create.uneditable_invoice_hint") }}</p>
      </div>
    </template>
    <template #action>
      <app-button
        :showf70Icon="false"
        :loading="loading"
        width=""
        class="ml-3"
        @click="send"
      >
        {{ $t("invoice.create.send_invoice") }}
      </app-button>
    </template>
  </app-modal>
  <div class="flex flex-col w-full h-screen bg-white overflow-auto">
    <div class="flex-none">
      <div class="flex flex-row h-full border-2">
        <div class="grid flex-none w-20 p-4 place-items-center">
          <app-link :to="{ name: 'invoice' }" class="text-black">
            <app-icon name="XIcon" class="w-5 cursor-pointer"></app-icon>
          </app-link>
        </div>
      </div>
    </div>
    <div class="flex flex-col md:flex-row">
      <div
        class="
          md:px-4
          lg:p-10
          md:py-5
          flex flex-col
          w-full
          pr-4
          mb-5
          md:mb-0
          space-y-8
        "
        :class="[showPreview ? 'md:w-1/2' : 'md:w-4/5']"
      >
        <p class="font-semibold text-xl">
          {{
            $t(
              mode === "edit"
                ? "invoice.action.edit_invoice"
                : "invoice.create.create_invoice_title"
            )
          }}
        </p>
        <form
          @submit.prevent="createInvoice"
          class="flex flex-col items-start space-y-8"
        >
          <div class="w-full pb-8 space-y-3 border-b-2">
            <p class="text-lg font-semibold">
              {{ $t("invoice.create.customer_title") }}
            </p>
            <app-form-customer
              :errorMessage="errors.customer_id"
              v-model="invoice.customer"
              @change="handleCustomerChanged"
            />
          </div>
          <div class="w-full pb-10 space-y-3 border-b-2">
            <p class="text-lg font-semibold">
              {{ $t("invoice.action.product_list") }}
            </p>
            <app-form-invoice-products />
          </div>
          <app-form-invoice-additional-details />
          <div class="flex w-full gap-2">
            <app-button
              :showf70Icon="false"
              :primary="false"
              :disabled="!submittable"
              :loading="loading"
              width="w-1/2"
              class="text-gray-500"
              @click="save"
            >
              {{ $t("invoice.create.save_draft") }}
            </app-button>
            <app-button
              type="submit"
              :loading="loading"
              :disabled="!submittable"
              :showProceedIcon="true"
              width="w-1/2"
              @click="confirmSending"
            >
              {{ $t("invoice.create.send_invoice") }}
            </app-button>
          </div>
        </form>
      </div>
      <div
        class="flex flex-col md:border-l md:px-4 md:py-3 lg:px-6 md:py-5"
        :class="[showPreview ? 'md:w-1/2' : 'md:w-1/5']"
      >
        <div
          :class="[
            'flex flex-col md:flex-row items-center md:space-x-4 mt-5',
            showPreview ? '' : 'justify-center',
          ]"
        >
          <p
            class="
              w-full
              my-2
              text-xl
              font-semibold
              text-left
              md:w-auto md:my-0
            "
            v-if="showPreview"
          >
            {{ $t("invoice.create.preview_title") }}
          </p>
          <app-button
            :primary="false"
            :showf70Icon="false"
            width="w-full md:w-auto"
            :class="[showPreview ? 'mb-0' : 'mb-4']"
            @click="togglePreview"
          >
            {{
              showPreview
                ? $t("invoice.create.preview_hide")
                : $t("invoice.create.preview_show")
            }}
          </app-button>
        </div>
        <div
          class="flex flex-row justify-center mt-4 md:justify-start"
          v-if="showPreview"
        >
          <app-button-menu
            class="
              flex
              items-center
              flex-none
              w-auto
              h-auto
              space-x-3
              font-medium
              border-b-2
              hover:border-primary
            "
            :rounded="false"
            :active="tabSelectedNo == 1"
            @onMenuClick="onTabClick(1)"
          >
            {{ $t("invoice.invoice_pdf.preview_tab_invoice_pdf") }}
          </app-button-menu>

          <app-button-menu
            class="
              flex
              items-center
              flex-none
              w-auto
              h-auto
              space-x-3
              font-medium
              border-b-2
              hover:border-primary
            "
            :rounded="false"
            :active="tabSelectedNo == 2"
            @onMenuClick="onTabClick(2)"
          >
            {{ $t("payment.create.preview_tab_payment_page") }}
          </app-button-menu>

          <app-button-menu
            class="
              flex
              items-center
              flex-none
              w-auto
              h-auto
              space-x-3
              font-medium
              border-b-2
              hover:border-primary
            "
            :rounded="false"
            :active="tabSelectedNo == 3"
            @onMenuClick="onTabClick(3)"
          >
            {{ $t("payment.create.preview_tab_email_page") }}
          </app-button-menu>

          <div class="flex-none w-0 border-b-2 md:w-auto md:flex-grow">
            &nbsp;
          </div>
        </div>
        <div
          class="flex flex-col items-center mt-2 md:mt-5"
          v-if="showPreview && tabSelectedNo == 1"
        >
          <InvoicePdf class="rounded-b-md" :previewMode="true"> </InvoicePdf>
        </div>
        <div
          class="flex flex-col items-center mt-2 md:mt-5"
          v-if="showPreview && tabSelectedNo == 2"
        >
          <div
            class="
              flex flex-row
              items-center
              w-full
              px-2
              py-2
              pr-3
              space-x-1
              bg-gray-200
              rounded-t-md
              md:space-x-3
            "
          >
            <div class="flex flex-row space-x-1">
              <div class="w-2 h-2 bg-gray-300 rounded-lg md:w-3 md:h-3"></div>
              <div class="w-2 h-2 bg-gray-300 rounded-lg md:w-3 md:h-3"></div>
              <div class="w-2 h-2 bg-gray-300 rounded-lg md:w-3 md:h-3"></div>
            </div>
            <div
              class="
                flex flex-row
                items-center
                px-2
                py-1
                bg-gray-100
                rounded-md
                md:w-full
              "
            >
              <app-icon name="LockClosedIcon" class="w-3 h-3 mr-1"></app-icon>
              <p class="text-xs">{{ payment_link_url }}</p>
            </div>
          </div>
          <PaymentPage :previewMode="true"> </PaymentPage>
        </div>
        <div
          class="flex flex-col items-center mt-2 md:mt-5"
          v-if="showPreview && tabSelectedNo == 3"
        >
          <EmailPage class="rounded-b-md" :previewMode="true"> </EmailPage>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import INVOICE_STATUS from "@/utils/const/invoice_status";
import InvoicePdf from "@/views/pages/main/invoice/invoice-pdf.vue";
import PaymentPage from "@/views/pages/main/invoice/payment-page.vue";
import EmailPage from "@/views/pages/main/invoice/email-page.vue";

export default {
  components: {
    InvoicePdf,
    PaymentPage,
    EmailPage,
  },

  data() {
    return {
      showConfirmation: false,
      showPreview: true,
      tabSelectedNo: 1,
      sequenceNo: null,
    };
  },

  async mounted() {
    this.$store.dispatch("invoiceStore/resetInvoice");
    this.$store.dispatch("customerStore/resetCustomerWithInitialCustomer");

    if (this.mode !== "create") {
      await this.loadInvoice();
      // adjust the data for the form
      if (this.mode === "duplicate") {
        // @TODO: remove invoice.id when the BE is consistent using _id or id on listing/detail invoice/customer/product
        delete this.invoice._id;
        delete this.invoice.id;
      }
      this.invoice.items.forEach((item) => {
        item._id = item.product_id;
      });
      if (this.invoice.tax_id && !this.invoice.tax_rate) {
        this.invoice.tax_rate = this.invoice.tax.rate;
      }
    }

    if (!this.invoice.invoice_no) {
      const invoiceRunningNo = await this.$store.dispatch(
        "invoiceStore/getNextRunningNo"
      );
      this.invoice.invoice_no = this.invoice.running_no =
        invoiceRunningNo["data"].running_no;
      this.invoice.next_running_no = invoiceRunningNo["data"].next_running_no;
    }
  },

  computed: {
    mode() {
      return this.$route.name.substring(8);
    },

    loading() {
      return this.$store.getters["invoiceStore/loading"];
    },

    invoice() {
      return this.$store.getters["invoiceStore/invoice"];
    },

    submittable() {
      const hasUnsubmittedProduct = this.invoice.items.reduce(
        (aggregate, item) => aggregate || !item._id,
        false
      );
      return (
        this.invoice.customer_id &&
        this.invoice.total_amount &&
        !hasUnsubmittedProduct
      );
    },

    errors() {
      return this.$store.getters["invoiceStore/errors"] ?? {};
    },

    payment_link_url() {
      return (
        process.env.VUE_APP_BASE_URL +
        "/invoice/pay?id=<" +
        this.$t("general.generated_id") +
        ">"
      );
    },
  },

  methods: {
    async loadInvoice() {
      const result = await this.$store.dispatch(
        "invoiceStore/getInvoiceById",
        this.$route.params.id
      );
      const customer = result.data.customer;
      // @TODO: remove this when the BE is consistent using _id or id on listing/detail invoice/customer/product
      if (customer._id === undefined) {
        customer._id = customer.id;
      }
      this.$store.dispatch(
        "customerStore/updateLocalCustomer",
        result.data.customer
      );
      delete this.invoice.status;
      delete this.invoice.created_at;
      delete this.invoice.updated_at;
    },

    handleCustomerChanged(customer) {
      if (this.invoice.customer.invoice_prefix) {
        this.invoice.invoice_no =
          this.invoice.customer.invoice_prefix + this.invoice.running_no;
      }
      this.invoice.customer_id = customer._id;
    },
    togglePreview() {
      this.showPreview = !this.showPreview;
    },
    onTabClick(tabNo) {
      this.tabSelectedNo = tabNo;
    },
    async getRunningNo() {
      const invoiceRunningNo = await this.$store.dispatch(
        "invoiceStore/getNextRunningNo"
      );
      this.sequenceNo = invoiceRunningNo.data;
    },

    async save() {

      if (!this.invoice._id) {
        this.invoice.status = INVOICE_STATUS.CREATED;
      }

      const result = await this.$store.dispatch(
        "invoiceStore/createOrUpdateInvoice",
        this.invoice
      );

      if (result && this.$lodash.isEmpty(result.errors)) {
        this.$router.push({ path: "/invoice" });
      }
    },
    confirmSending() {
      this.showConfirmation = true;
    },
    closeConfirmation() {
      this.showConfirmation = false;
    },

    async send() {
      this.invoice.status = INVOICE_STATUS.OPEN;
      const result = await this.$store.dispatch(
        "invoiceStore/createOrUpdateInvoice",
        this.invoice
      );

      if (result && this.$lodash.isEmpty(result.errors)) {
        this.$router.push({ path: "/invoice" });
      }
      // if failed
      this.invoice.status = INVOICE_STATUS.CREATED;
      this.closeConfirmation();
    },
  },
};
</script>
