<template>
  <div class="flex flex-col items-center w-full px-3 pt-10 bg-gray-800 md:px-0">
    <div
      class="flex flex-col 
      w-4/5 
      max-w-sm 
      py-4 
      mt-4 
      mb-2 
      text-center
    bg-white 
      rounded-md 
      md:max-w-2xl"
    >
      <div class="flex justify-between px-8">
        <div class="flex flex-col items-start justify-start py-5">
          <app-image
            v-if="getBusinessLogo()"
            :src="getBusinessLogo()"
            class="flex-none w-16 bg-white"
          />
          <app-image
            v-else-if="getBusinessIcon()"
            :src="getBusinessIcon()"
            class="flex-none w-16 bg-white"
          />
          <div class="pt-4">
            <p class="mb-2 text-sm font-medium text-gray-900 uppercase">
              {{ business?.name }}
            </p>
          </div>
          <div>
            <p class="mb-2 text-sm text-gray-400 uppercase">
              {{ business?.detail?.company_reg_no }}
            </p>
          </div>
        </div>
        <div class="flex flex-col justify-end py-5 text-right">
          <p class="mb-2 font-bold text-right uppercase">
            {{ $t("layout.header.invoice") }}
          </p>
          <app-test-mode-badge :testMode="testMode" />
          <p class="text-sm text-gray-400">
            {{ $t("invoice.invoice_pdf.amount_to_pay") }}
          </p>
          <p class="text-2xl font-bold">
            {{ $formats.currency(invoice.currency, invoice.total_amount) }}
          </p>
          <!-- <p class="text-sm text-gray-400">
            {{ $t(`invoice.create.types.${invoice.type}`) }}
          </p> -->
        </div>
      </div>

      <div class="w-full mb-2 border-t"></div>
      <div class="flex flex-row">
        <div class="flex flex-col justify-start mx-8 space-y-2">
          <div class="flex flex-row items-center space-x-4">
            <p class="w-8 text-sm text-left text-gray-400">
              {{ $t("general.from") }}
            </p>
            <p class="text-sm">{{ business?.name }}</p>
          </div>
          <div class="flex flex-row items-center space-x-4">
            <p class="w-8 text-sm text-left text-gray-400">
              {{ $t("general.to") }}
            </p>
            <p class="text-sm">{{ customer.name }}</p>
          </div>
          <div class="flex flex-row items-center space-x-4">
            <p class="w-8 text-sm text-left text-gray-400">
              {{ $t("invoice.invoice_pdf.memo") }}
            </p>
            <p class="text-sm break-words">{{ invoice.memo }}</p>
          </div>
          <div class="flex flex-row items-center space-x-4">
            <app-button-outline>
              <app-icon-download></app-icon-download>
              {{ $t("invoice.action.download_invoice") }}
            </app-button-outline>
          </div>
        </div>
      </div>
    </div>

    <div
      class="flex flex-col
            w-4/5 
            max-w-sm 
            px-5 
            py-4 
            mt-4 
            mb-4 
            text-center 
            bg-white 
            rounded-md 
            md:max-w-2xl"
    >
      <p class="text-left text-gray-400 mb-5">
        {{ $t("invoice.action.product_list") }}
      </p>
      <div class="overflow-x-auto">
        <table
          class="table min-w-full border-b"
          v-for="product in invoice.items"
          :key="product._id"
        >
          <tr class="p-4 text-left">
            <td class="pl-8 w-2/5">
              <app-text-readmore :showReadMore="false" class="font-bold text-left" :text="product.name ?? '-'" />
            </td>
            <td>( {{ product.quantity }} )</td>
            <td>
              {{ $formats.currency(product.currency, product.price) }}/{{
                product.unit_label
              }}
            </td>
            <td></td>
            <td class="text-right">
              <p>
                {{
                  $formats.currency(
                    product.currency,
                    getUsedAmount(product.price, product.quantity)
                  )
                }}
              </p>
            </td>
          </tr>

          <template v-if="product.tax_id">
            <tr class="space-y-2 text-gray-400 row">
              <td class="pt-8" colspan="3"></td>
              <td class="text-right">
                <p>
                  {{
                    getUsedTaxName(
                      product.tax_name,
                      product.tax_rate,
                      product.tax_type
                    )
                  }}
                </p>
              </td>
              <td class="pr-8 text-right">
                <p>
                  {{
                    $formats.currency(
                      product.currency,
                      getUsedAmountWithTax(
                        getUsedAmount(product.price, product.quantity),
                        product.tax_rate,
                        product.tax_type
                      ).toString()
                    )
                  }}
                </p>
              </td>
            </tr>
          </template>
        </table>
        <table class="table min-w-full">
          <tbody>
            <tr class="p-5 mx-0 border-t row">
              <td class="pt-8" colspan="3"></td>
              <td class="pt-8 text-right">
                <p class="font-bold">
                  {{ $t("invoice.invoice_pdf.subtotal") }}:
                </p>
              </td>
              <td class="pt-8 pr-8 text-right">
                <p class="font-bold">
                  {{
                    $formats.currency(invoice.currency, getSummarySubtotal())
                  }}
                </p>
              </td>
            </tr>
            <tr class="p-5 row">
              <td class="pt-8" colspan="3"></td>
              <td class="pt-8 text-right">
                <p class="font-bold">
                  {{ $t("invoice.invoice_pdf.total_excluding_tax") }}:
                </p>
              </td>
              <td class="pt-8 pr-8 text-right">
                <p class="font-bold">
                  {{
                    $formats.currency(
                      invoice.currency,
                      getSummaryAmountExcludingTax()
                    )
                  }}
                </p>
              </td>
            </tr>
            <tr class="row">
              <td class="pt-8" colspan="3"></td>
              <td class="pt-4 text-right">
                <p class="text-right text-gray-400">
                  {{ $t("invoice.invoice_pdf.total_tax") }}:
                </p>
              </td>
              <td class="pr-8 text-right">
                <p class="text-gray-400">
                  {{
                    $formats.currency(invoice.currency, getTotalTax())
                  }}
                </p>
              </td>
            </tr>
            <tr class="row">
              <td class="pt-8" colspan="3"></td>
              <td class="pt-4 text-right">
                <p class="text-lg font-semibold text-right text-primary">
                  {{ $t("invoice.invoice_pdf.total_due") }}:
                </p>
              </td>
              <td class="pr-8 text-right">
                <p class="text-lg font-semibold text-primary">
                  {{
                    $formats.currency(invoice.currency, invoice.total_amount)
                  }}
                </p>
              </td>
            </tr>
            <tr class="row">
              <td class="pt-8" colspan="4"></td>
              <td class="pr-8 text-right">
                <p class="text-gray-400 capitalize">
                  {{
                    invoice.type?.replace(/_/g, " ")
                  }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="w-full mb-2 border-t"></div>
      <div class="text-left text-sm">
        <p class="my-2">
          {{ $t("invoice.email.payment_id") }}
          {{ invoice.invoice_no ? invoice.invoice_no : "-" }}
        </p>
        <p class="my-2">
          {{
            $t("invoice.email.question_description", {
              email: business.support_detail.support_email,
              tel_no: business.support_detail.support_tel_no,
            })
          }}
        </p>
        <p class="mt-5">{{ $t("payment.email.regards") }}</p>
        <p class="mb-5">{{ business?.name }}</p>
        <div class="border-t mb-2"></div>
        <p class="my-2">
          {{ $t("payment.email.having_trouble") }}
        </p>
        <p class="py-2 my-2 text-center text-blue-600 bg-blue-100">
          {{ payment_link_url }}
        </p>
      </div>
    </div>
    <div class="justify-center m-10">
      <app-logo-powered-by-swipe-light />
    </div>
  </div>
</template>

<script>
import TAX_TYPE from "@/utils/const/tax_type";

export default {
  props: {
    masterBusinessMode: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      testMode: false,
      TAX_TYPE: TAX_TYPE,
    };
  },
  async mounted() {
    if (this.$route.query.id == null) {
      this.testMode = this.$store.getters["businessStore/test_mode"] ?? false;
      await this.getDataForPreview();
      return;
    }
  },

  computed: {
    invoice() {
      return this.$store.getters["invoiceStore/invoice"];
    },
    customer() {
      return this.$store.getters["customerStore/customer"];
    },
    customerBillingAddress() {
      return this.$store.getters["customerStore/customerAddress"]("billing");
    },
    customerShippingAddress() {
      return this.$store.getters["customerStore/customerAddress"]("shipping");
    },
    business() {
      return this.masterBusinessMode
        ? this.payment_link?.business
        : this.$store.getters["businessStore/business"];
    },
    payment_link_url() {
      return (
        process.env.VUE_APP_BASE_URL +
        "/invoice/pay?id=<" +
        this.$t("general.generated_id") +
        ">"
      );
    },
  },

  methods: {
    getBusinessLogo() {
      return this.business?.media?.find(
        (media) => media.collection_name == "logo"
      )?.original_url;
    },
    getBusinessIcon() {
      return this.business.media?.find(
        (media) => media.collection_name == "icon"
      )?.original_url;
    },
    getUsedTaxName(name, rate, type) {
      let taxUsedTypePostfix = type == TAX_TYPE.INCLUSIVE ? "incl." : "excl.";
      return `Tax ${name} (${rate}% ${taxUsedTypePostfix})`;
    },
    getUsedAmount(price, quantity) {
      return price * quantity;
    },
    getUsedAmountWithTax(amount, rate, type) {
      if (type == TAX_TYPE.INCLUSIVE || type == TAX_TYPE.EXCLUSIVE) {
        return (amount * rate) / 100;
      } else {
        return amount;
      }
    },
    getTaxAmount(amount, rate, type){
      if (type == TAX_TYPE.INCLUSIVE || type == TAX_TYPE.EXCLUSIVE) {
        return (amount * rate) / 100;
      } else {
        return 0;
      }
    },
    getSummaryAmountExcludingTax() {
      let newAmount = 0;

      this.invoice.items.forEach((item) => {
        if (item.tax_type == TAX_TYPE.INCLUSIVE) {
          newAmount +=
            this.getUsedAmount(item.price, item.quantity) -
            this.getUsedAmountWithTax(
              this.getUsedAmount(item.price, item.quantity),
              item.tax_rate,
              item.tax_type
            );
        } else {
          newAmount = newAmount + this.getUsedAmount(item.price, item.quantity);
        }
      });

      return newAmount;
    },
    getSummarySubtotal() {
      let newAmount = 0;

      this.invoice.items.forEach((item) => {
        newAmount += this.getUsedAmount(item.price, item.quantity);
      });

      return newAmount;
    },
    getTotalTax() {
      let amount = 0;

      this.invoice.items.forEach((item) => {
        amount += this.getTaxAmount(
          this.getUsedAmount(item.price, item.quantity),
          item.tax_rate,
          item.tax_type
        );
      });

      return amount;
    },
  },
};
</script>

<style scoped>
td {
  padding: 5px;
}
</style>
